@import "~antd/dist/antd.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Be Vietnam Pro" !important;
  background: #f5f5f5 !important;
}
:root {
  scrollbar-width: thin !important;
}
code {
  font-family: "Be Vietnam Pro" !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

a {
  text-decoration: none;
}

.scrollable-element {
  scrollbar-width: thin !important;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: hsla(0, 0%, 39.2%, 0.1);
  border-radius: 100px;
  height: 5px;
}

.react-pdf__Page__canvas {
  margin: 0 auto !important;
}
/* Track */
::-webkit-scrollbar-track {
  width: 3px;
  background-color: #f5f5f5;
  border-radius: 100px;
  height: 3px;
}

#toolbarViewer {
  height: 0px !important;
}
#toolbarViewerRight {
  display: none !important;
}
#outerContainer #mainContainer div.toolbar {
  display: none !important; /* hide PDF viewer toolbar */
}
#outerContainer #mainContainer #viewerContainer {
  top: 0 !important; /* move doc up into empty bar space */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bd9696;
  border-radius: 20px;
  height: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(164, 155, 155, 0.8);
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
}

.g2-html-annotation {
  font-size: 18px !important;
  color: #e9a051;
}

.g2-tooltip-value {
  margin-left: 5px !important;
}
.slick-dots li.slick-active button:before {
  color: #137bbd !important;
  font-size: 10px !important;
}
.slick-dots {
  bottom: -35px !important;
}

@media only screen and (max-width: 500px) {
  .g2-html-annotation {
    font-size: 14px !important;
  }
}

.MuiButton-root.Mui-disabled { 
  opacity: 0.4;
  color: #fff !important;
}